import * as React from "react";
import { Helmet } from "react-helmet";

import Header from "../components/Header";
import Head from "../components/Head";
import Footer from "../components/Footer";

import "../styles/thanks.scss";

const Thanks = () => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Huet & Haie | Mentions légales</title>
        <meta
          name="description"
          content="Huet & Haie, situé à Vair-sur-Loire, près d’Ancenis, vous accompagne dans vos travaux d’aménagements et de restructuration de vos extérieurs, pour tous types de maisons individuelles ou d’habitats collectifs."
        />
        <link rel="canonical" href="https://huetethaie.fr/" />
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>
      <Head />
      <Header />
      <main id="main" className="site-main" role="main">
        <section id="thanks" className="thanks">
          <header>
            <div className="h1">Merci !</div>
          </header>
          <div className="container thanks__container">
            <p>
              Nous avons bien reçu votre message et revenons vers vous
              rapidement.
            </p>
            <p>
              <strong>L'équipe Huet & Haie</strong>
            </p>
          </div>
        </section>
      </main>
      <Footer />
    </>
  );
};

export default Thanks;
